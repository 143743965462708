'use strict';

angular.module('informaApp')
    .directive('infProgressChart', function () {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-progress-chart/template.ptl.html',
            scope: {
                data: "=",
                showTotal: "=",
                hidePhases: "=",
                callbacks: "="
            },
            link: function (scope, element, attr) {
                scope.$watch("callbacks", function (newValue) {
                    if (newValue){
                        if (newValue.onTotalClick){
                            scope.clickTotal = function (phase) {
                                if (newValue && newValue.onTotalClick){
                                    newValue.onTotalClick(phase, newValue.args);
                                }
                            }
                        }

                        if (newValue.clickOnValue){

                            scope.clickOnValue = function (phase) {
                                if (newValue && newValue.clickOnValue){
                                    newValue.clickOnValue(phase, newValue.args);
                                }
                            }
                        }
                    }
                }, true)
            }
        }
    });